/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKProgress from "components/MKProgress";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Sections components
// import BaseLayout from "layouts/sections/components/BaseLayout";
// import View from "layouts/sections/components/View";

// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

// Faq page code
// import faqCode from "layouts/sections/page-sections/faq/code";

function FaqList({
  userAccess,
  head1,
  head2,
  title,
  title1,
  title2,
  title3,
  title4,
  title5,
  info1,
  info2,
  info3,
  info4,
  info5,
  progress,
  nextpage,
  lastpage,
}) {
  const [collapse, setCollapse] = useState(false);
  //const localURL = "http://localhost:3000";
  const liveURL = "https://demo.isongacyber.rw";
  const path = "/#/training";

  return (
    <MKBox component="section" py={2}>
      <MKProgress color="primary" value={progress} />
      <MKBox component="nav" position="relative" width="100%">
        <Container py={5}>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              {head1} {head2}
            </MKTypography>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
            </MKBox>
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              {lastpage <= 0 ? (
                <MKButton px={2} color="primary" href={"/start"}>
                  <MdArrowBack /> &nbsp; Back
                </MKButton>
              ) : (
                <MKButton px={2} color="primary" href={"/lesson/" + lastpage}>
                  <MdArrowBack /> &nbsp; Back
                </MKButton>
              )}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={5} md={10} py={1}>
            <MKTypography
              variant="h3"
              color="secondary"
              fontWeight="regular"
              align="center"
              py={0.8125}
              mr={2}
            >
              {title}
            </MKTypography>
            <FaqCollapse
              title={title1}
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              {info1}
            </FaqCollapse>
            <FaqCollapse
              title={title2}
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              {info2}
            </FaqCollapse>
            <FaqCollapse
              title={title3}
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              {info3}
            </FaqCollapse>
            <FaqCollapse
              title={title4}
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              {info4}
            </FaqCollapse>
            <FaqCollapse
              title={title5}
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
              {info5}
            </FaqCollapse>
          </Grid>
          {nextpage == 0 ? (
            userAccess >= "2" ? (
              <MKButton color="primary" href={`${liveURL}${path}`} fullWidth>
                <MdArrowBack /> &nbsp; Return To Training
              </MKButton>
            ) : (
              <MKButton color="primary" href="/complete" fullWidth>
                <MdCheckCircle />
                &nbsp; Complete Training
              </MKButton>
            )
          ) : (
            <MKButton px={2} fullWidth color="primary" href={"/lesson/" + nextpage}>
              Next &nbsp; <MdArrowForward />
            </MKButton>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

FaqList.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "",
  title: "Page Title",
  title1: "Page Title",
  title2: "Page Title",
  title3: "Page Title",
  title4: "Page Title",
  title5: "Page Title",
  info1: "Details",
  info2: "Details",
  info3: "Details",
  info4: "Details",
  info5: "Details",
  progress: 0,
  nextpage: "",
  lastpage: "",
};

FaqList.propTypes = {
  userAccess: PropTypes.string,
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  title1: PropTypes.string,
  title2: PropTypes.string,
  title3: PropTypes.string,
  title4: PropTypes.string,
  title5: PropTypes.string,
  title6: PropTypes.string,
  info1: PropTypes.string,
  info2: PropTypes.string,
  info3: PropTypes.string,
  info4: PropTypes.string,
  info5: PropTypes.string,
  progress: PropTypes.number,
  nextpage: PropTypes.string,
  lastpage: PropTypes.string,
};

export default FaqList;
