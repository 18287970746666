// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";
import PropTypes from "prop-types";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";
// Images
// import bgImage from "assets/images/isonga/africa_locks.png";
// import bgImage from "assets/images/examples/city.jpg";
import team1 from "assets/images/team-5.jpg";
// import team2 from "assets/images/bruce-mars.jpg";
// import team3 from "assets/images/ivana-squares.jpg";
// import team4 from "assets/images/ivana-square.jpg";

function TwoRowInfo({
  userAccess,
  head1,
  head2,
  title,
  item1,
  item2,
  item1_title,
  item2_title,
  item_footer,
  nextpage,
  lastpage,
  progress,
}) {
  //const localURL = "http://localhost:3000";
  const liveURL = "https://demo.isongacyber.rw";
  const path = "/#/training";
  return (
    <MKBox component="section" position="relative" height="800px" py={2}>
      <Container>
        <MKProgress color="primary" value={progress} />
        <MKBox component="nav" position="relative" width="100%">
          <Container py={5}>
            <Grid container flexDirection="row" alignItems="center">
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
                {head1} {head2}
              </MKTypography>
              <MKBox
                component="ul"
                display={{ xs: "none", lg: "flex" }}
                p={0}
                my={0}
                mx="auto"
                sx={{ listStyle: "none" }}
              >
                <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
              </MKBox>
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
                {lastpage <= 0 ? (
                  <MKButton px={2} color="primary" href={"/start"}>
                    <MdArrowBack /> &nbsp; Back
                  </MKButton>
                ) : (
                  <MKButton px={2} color="primary" href={"/lesson/" + lastpage}>
                    <MdArrowBack /> &nbsp; Back
                  </MKButton>
                )}
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            {/* <MKBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="3rem"
              height="3rem"
              variant="gradient"
              bgColor="info"
              color="white"
              shadow="md"
              borderRadius="lg"
              mb={2}
            >
              <Icon>supervisor_account</Icon>
            </MKBox> */}
            <MKTypography variant="h3" color="blue">
              {title}
            </MKTypography>
            {/* <MKTypography variant="body1" color="white" opacity={0.8}>
              There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to get good
              at. That&apos;s my skill.
            </MKTypography> */}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name={item1_title}
                position={{ color: "info", label: "UI Designer" }}
                description={item1}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name={item2_title}
                position={{ color: "info", label: "UI Designer" }}
                description={item2}
              />
            </MKBox>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <MKBox mb={1}>
            <HorizontalTeamCard
              image={team1}
              name={item_footer}
              position={{ color: "info", label: "UI Designer" }}
            />
          </MKBox>
        </Grid>
        {nextpage == 0 ? (
          userAccess >= "2" ? (
            <MKButton color="primary" href={`${liveURL}${path}`} fullWidth>
              <MdArrowBack /> &nbsp; Return To Training
            </MKButton>
          ) : (
            <MKButton color="primary" href="/complete" fullWidth>
              <MdCheckCircle />
              &nbsp; Complete Training
            </MKButton>
          )
        ) : (
          <MKButton px={2} fullWidth color="primary" href={"/lesson/" + nextpage}>
            Next &nbsp; <MdArrowForward />
          </MKButton>
        )}
      </Container>
    </MKBox>
  );
}

TwoRowInfo.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "( 5 of 5 )",
  title: "Page Title",
  item1: "item1 info kitu kimoja kufanya",
  item2: "item2 info vitu wawili kufanya",
  item1_title: "item 1 title",
  item2_title: "item 2 title",
  item_footer: "The Footer goes here",
  description:
    "Page Description goes here. Kila siku ninahitaji kula chakula vitamu. Nataka matunde na mboga na nyama mingi sana.",
};

TwoRowInfo.propTypes = {
  userAccess: PropTypes.string,
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  item1: PropTypes.string,
  item2: PropTypes.string,
  item1_title: PropTypes.string,
  item2_title: PropTypes.string,
  description: PropTypes.string,
  item_footer: PropTypes.string,
  progress: 0,
  nextpage: "",
  lastpage: "",
};

export default TwoRowInfo;
