/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";

// Coworking page component
// import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function ThreeItemsVertical({
  userAccess,
  head1,
  head2,
  title,
  item1,
  item2,
  nextpage,
  lastpage,
  progress,
}) {
  //const localURL = "http://localhost:3000";
  const liveURL = "https://demo.isongacyber.rw";
  const path = "/#/training";
  return (
    <MKBox component="section" py={2}>
      <MKProgress color="primary" value={progress} />
      <MKBox component="nav" position="relative" width="100%">
        <Container py={5}>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              {head1} {head2}
            </MKTypography>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
            </MKBox>
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              {lastpage <= 0 ? (
                <MKButton px={2} color="primary" href={"/start"}>
                  <MdArrowBack /> &nbsp; Back
                </MKButton>
              ) : (
                <MKButton px={2} color="primary" href={"/lesson/" + lastpage}>
                  <MdArrowBack /> &nbsp; Back
                </MKButton>
              )}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={3}>
            <MKTypography variant="h4" my={1} py={8}>
              {title}
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={2}>
              {/* {description} */}
            </MKTypography>
            <MKTypography
              component="a"
              href="#"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              {/* More about us
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKTypography variant="body1" mb={1} py={8}>
                {item1}
              </MKTypography>
              <MKTypography variant="body1" mb={1} py={0}>
                {item2}
              </MKTypography>
            </Stack>
            {nextpage == 0 ? (
              userAccess >= "2" ? (
                <MKButton color="primary" href={`${liveURL}${path}`} fullWidth>
                  Return To Trainings Page
                </MKButton>
              ) : (
                <MKButton color="primary" href="/complete" fullWidth>
                  Complete Training
                </MKButton>
              )
            ) : (
              <MKButton
                variant="outlined"
                px={2}
                fullWidth
                color="primary"
                href={"/lesson/" + nextpage}
              >
                Next &nbsp; <MdArrowForward />
              </MKButton>
            )}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

ThreeItemsVertical.propTypes = {
  userAccess: PropTypes.string,
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  item1: PropTypes.string,
  item2: PropTypes.string,
  lastpage: PropTypes.string,
  nextpage: PropTypes.string,
  progress: PropTypes.string,
};

export default ThreeItemsVertical;
