// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";
import PropTypes from "prop-types";

// Images
// import bgImage from "assets/images/isonga/africa_locks.png";
// import bgImage from "assets/images/examples/city.jpg";
import team1 from "assets/images/team-5.jpg";
// import team2 from "assets/images/bruce-mars.jpg";
// import team3 from "assets/images/ivana-squares.jpg";
// import team4 from "assets/images/ivana-square.jpg";

function TwoRowInfo({ head1, head2 }) {
  return (
    <MKBox
      component="section"
      position="relative"
      height="800px"
      py={2}
      // sx={{
      //   backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
      //     `
      //     ${linearGradient(
      //       rgba(gradients.dark.main, 0.5),
      //       rgba(gradients.dark.state, 0.5)
      //     )}, url(${bgImage})`,
      // }}
    >
      <Container>
        <MKProgress color="dark" value={100} />
        <MKBox component="nav" position="relative" width="100%">
          <Container py={5}>
            <Grid container flexDirection="row" alignItems="center">
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
                {head1} {head2}
              </MKTypography>
              <MKBox
                component="ul"
                display={{ xs: "none", lg: "flex" }}
                p={0}
                my={0}
                mx="auto"
                sx={{ listStyle: "none" }}
              >
                <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
              </MKBox>
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
                <MKButton color="default" href="/lesson/page4">
                  Go Back
                </MKButton>
                <MKButton color="default" href="/lesson/complete">
                  Next Page
                </MKButton>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            {/* <MKBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="3rem"
              height="3rem"
              variant="gradient"
              bgColor="info"
              color="white"
              shadow="md"
              borderRadius="lg"
              mb={2}
            >
              <Icon>supervisor_account</Icon>
            </MKBox> */}
            <MKTypography variant="h3" color="blue">
              Security Tips
            </MKTypography>
            {/* <MKTypography variant="body1" color="white" opacity={0.8}>
              There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to get good
              at. That&apos;s my skill.
            </MKTypography> */}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Passwords:"
                position={{ color: "info", label: "UI Designer" }}
                description="It is recommended to change your password periodically, ideally every few months, to enhance security."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="More:"
                position={{ color: "info", label: "UI Designer" }}
                description="However, It's important to note that while multi-factor authentication is a powerful security measure, it should not be considered a replacement for other security best practices, such as using strong passwords, keeping software up to date, and maintaining good cybersecurity hygiene."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

TwoRowInfo.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "( 5 of 5 )",
  title: "Page Title",
  description:
    "Page Description goes here. Kila siku ninahitaji kula chakula vitamu. Nataka matunde na mboga na nyama mingi sana.",
  item1: "item1 info kitu kimoja kufanya",
  item2: "item2 info vitu wawili kufanya",
  item3: "item3 info vitu vitatu kufanya",
};

TwoRowInfo.propTypes = {
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  item1: PropTypes.string,
  item2: PropTypes.string,
  item3: PropTypes.string,
};

export default TwoRowInfo;
