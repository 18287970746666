/* eslint-disable no-unused-vars */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
// import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { MdArrowForward } from "react-icons/md";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
//import bgImage from "assets/images/isonga/green_cyber.png";
// eslint-disable-next-line no-undef
const SERVER_URL = process.env.REACT_APP_TRAINING_URL;
function HeaderPage({
  userAccess,
  head1,
  pageNumber,
  pageTotal,
  duration,
  head2,
  title,
  description,
  button,
  trainingPreviewButton,
  nextpage,
  background,
}) {
  let myParam = "";
  let userId = "";
  let trainingId = "";

  if (!localStorage.getItem("contentValue") || localStorage.getItem("contentValue") === "null") {
    const urlParams = new URLSearchParams(window.location.search);
    myParam = urlParams.get("content");
    userId = urlParams.get("userId");
    trainingId = urlParams.get("trainingId");

    if (myParam && userId && trainingId) {
      localStorage.setItem("contentValue", myParam);
      localStorage.setItem("userId", userId);
      localStorage.setItem("trainingId", trainingId);

      // const baseUrl = window.location.href.split("?")[0];
      // window.history.replaceState({}, document.title, baseUrl);
    }
  } else {
    myParam = localStorage.getItem("contentValue");
    userId = localStorage.getItem("userId");
    trainingId = localStorage.getItem("trainingId");
    // const baseUrl = window.location.href.split("?")[0];
    // window.history.replaceState({}, document.title, baseUrl);
  }
  const [imageReference, setImageReference] = useState("");

  useEffect(() => {
    fetch(SERVER_URL + "/media/getMediaReference/", {
      method: "POST",
      body: JSON.stringify({
        contentId: myParam,
        contentPage: 0,
        userId,
        trainingId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setImageReference(result.imageReference);
        },
        (error) => {
          alert(error);
        }
      );
  });

  //--- CHeck if user access has value first ---
  if (userAccess) {
    button = "Begin Trainig";
  }

  return (
    <>
      <MKBox component="header" position="relative" height="800px">
        <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
          <Container>
            <Grid container flexDirection="row" alignItems="center">
              <MKTypography color="white" fontWeight="regular" py={0.8125} mr={2}>
                {head1}
              </MKTypography>
              <MKBox
                component="ul"
                display={{ xs: "none", lg: "flex" }}
                p={0}
                my={0}
                mx="auto"
                sx={{ listStyle: "none" }}
              >
                <MKBox component="li"></MKBox>
                <MKBox component="li"></MKBox>
              </MKBox>
              {pageNumber && pageTotal && duration && (
                <MKTypography color="white" fontWeight="regular" py={0.8125} mr={2}>
                  {"("} Pages: {pageNumber} of {pageTotal} - Duration: {duration} min {")"}
                </MKTypography>
              )}

              {/* <MKButton color="default">Begin Training</MKButton> */}
            </Grid>
          </Container>
        </MKBox>
        <MKBox
          display="flex"
          alignItems="center"
          minHeight="100%"
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.4),
                rgba(gradients.dark.state, 0.4)
              )}, url(${`https://cyberback.a2k.solutions/media/uploads/${imageReference}`})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={6}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              mx="auto"
            >
              <MKTypography
                variant="h2"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
                mb={3}
              >
                {title}
              </MKTypography>
              <MKTypography variant="h5" color="white" mt={1} mb={{ xs: 3, sm: 8 }} px={1}>
                {description}
              </MKTypography>
              <MKButton color="primary" href={nextpage}>
                {userAccess >= "2" ? trainingPreviewButton : button} &nbsp; <MdArrowForward />
              </MKButton>
            </Grid>
          </Container>
        </MKBox>
      </MKBox>
    </>
  );
}

// Setting default props for the ExampleCard
HeaderPage.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "[Pages: ? - Duration: ?? min]",
  pageNumber: "",
  pageTotal: "",
  duration: "",
  title: "",
  description: "",
  button: "",
  trainingPreviewButton: "Preview Next Page",
  nextpage: "/lesson/1",
  background: "",
};

// Typechecking props for the ExampleCard
HeaderPage.propTypes = {
  userAccess: PropTypes.string,
  head1: PropTypes.string,
  head2: PropTypes.string,
  pageNumber: PropTypes.string,
  pageTotal: PropTypes.string,
  duration: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.string,
  trainingPreviewButton: PropTypes.string,
  nextpage: PropTypes.string,
  background: PropTypes.string,
};

export default HeaderPage;
