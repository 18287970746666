/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";
import PropTypes from "prop-types";

function BlogText({
  userAccess,
  head1,
  head2,
  title,
  item1,
  item2,
  item3,
  progress,
  nextpage,
  lastpage,
}) {
  //const localURL = "http://localhost:3000";
  const liveURL = "https://demo.isongacyber.rw";
  const path = "/#/training";

  return (
    <MKBox component="section" position="relative" height="800px" py={2}>
      <Container>
        <MKProgress color="primary" value={progress} />
        <MKBox component="nav" position="relative" width="100%">
          <Container py={5}>
            <Grid container flexDirection="row" alignItems="center">
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
                {head1} {head2}
              </MKTypography>
              <MKBox
                component="ul"
                display={{ xs: "none", lg: "flex" }}
                p={0}
                my={0}
                mx="auto"
                sx={{ listStyle: "none" }}
              >
                <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
              </MKBox>
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
                {lastpage <= 0 ? (
                  <MKButton px={2} color="primary" href={"/start"}>
                    <MdArrowBack /> &nbsp; Back
                  </MKButton>
                ) : (
                  <MKButton px={2} color="primary" href={"/lesson/" + lastpage}>
                    <MdArrowBack /> &nbsp; Back
                  </MKButton>
                )}
              </MKTypography>
              {/* <MKButton color="default">Begin Training</MKButton> */}
            </Grid>
          </Container>
        </MKBox>
        <Grid container py={5} spacing={3} item xs={12} lg={8} mx="auto">
          {/* <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
          >
            The Idea
          </MKTypography> */}
          <MKTypography variant="h3" mb={3}>
            {title}
          </MKTypography>
          <MKTypography variant="body2">
            {item1}
            <br /> <br />
            {item2}
            <br /> <br />
            {item3}
            <br /> <br />
          </MKTypography>
          {nextpage == 0 ? (
            userAccess >= "2" ? (
              <MKButton color="primary" href={`${liveURL}${path}`} fullWidth>
                <MdArrowBack /> &nbsp; Return To Training
              </MKButton>
            ) : (
              <MKButton color="primary" href="/complete" fullWidth>
                <MdCheckCircle />
                &nbsp; Complete Training
              </MKButton>
            )
          ) : (
            <MKButton px={2} fullWidth color="primary" href={"/lesson/" + nextpage}>
              Next &nbsp; <MdArrowForward />
            </MKButton>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

BlogText.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "( 1 of 5 )",
  title: "Page Title",
  item1: "item1 info kitu kimoja kufanya",
  item2: "item2 info vitu wawili kufanya",
  item3: "item3 info vitu wawili kufanya",
  item1_title: "item 1 title",
  item2_title: "item 2 title",
  item3_title: "item 3 title",
  progress: 0,
  nextpage: "",
};

BlogText.propTypes = {
  userAccess: PropTypes.string,
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  item1: PropTypes.string,
  item2: PropTypes.string,
  item3: PropTypes.string,
  progress: 0,
  nextpage: "",
  lastpage: "",
};
export default BlogText;
