import React, { useEffect, useState } from "react";
import HeaderPage from "training/templates/HeaderPage";

const StartPage = () => {
  const SERVER_URL = process.env.REACT_APP_TRAINING_URL;
  const SERVER_PAGE = process.env.REACT_APP_TRAINING_PAGE;

  const [pageData, setPageData] = useState({});

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    let content = queryParameters.get("content");
    let userId = queryParameters.get("userId");
    let trainingId = queryParameters.get("trainingId");

    if (content && userId && trainingId) {
      localStorage.setItem("contentValue", content);
      localStorage.setItem("userId", userId);
      localStorage.setItem("trainingId", trainingId);
    } else {
      const storedContent = localStorage.getItem("contentValue");
      const storedUserId = localStorage.getItem("userId");
      const storedTrainingId = localStorage.getItem("trainingId");

      if (storedContent && storedUserId && storedTrainingId) {
        // If the values are in local storage, use them
        content = storedContent;
        userId = storedUserId;
        trainingId = storedTrainingId;
      }
    }

    fetch(SERVER_URL + SERVER_PAGE, {
      method: "POST",
      body: JSON.stringify({
        contentId: content,
        contentPage: 0,
        userId,
        trainingId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setPageData(result);
        },
        (error) => {
          alert(error);
        }
      );
  }, []);

  return (
    <HeaderPage
      userAccess={pageData.userAccess}
      head1={pageData.trainingName}
      pageNumber={pageData.pageNumber}
      pageTotal={pageData.pageTotal}
      duration={pageData.duration}
      title={pageData.pageHeader}
      description={pageData.pageInfo}
    />
  );
};

export default StartPage;
