/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Copyright 2023 A2K Solutions, Ltd
*/
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ThreeItemsVertical from "training/templates/ThreeItemsVertical";
import VideoPageTemplate from "training/templates/VideoPageTemplate";
import FaqList from "training/templates/FaqList";
import TwoItems from "training/templates/TwoItems";
import TwoRowInfo from "training/templates/TwoRowInfo";
import LoadingPage from "training/templates/Loading";
import FeaturesThree from "training/templates/FeaturesThree";
import ApplicationTwo from "training/templates/ApplicationTwo";
import BlogText from "training/templates/BlogText";

const LessonPage = () => {
  const SERVER_URL = process.env.REACT_APP_TRAINING_URL;
  const SERVER_PAGE = process.env.REACT_APP_TRAINING_PAGE;

  const [pageData, setPage] = useState(<LoadingPage />);
  const { id } = useParams();

  const myParam = localStorage.getItem("contentValue");
  const userId = localStorage.getItem("userId");
  const trainingId = localStorage.getItem("trainingId");

  useEffect(() => {
    fetch(SERVER_URL + SERVER_PAGE, {
      method: "POST",
      body: JSON.stringify({
        contentId: myParam,
        contentPage: id,
        userId,
        trainingId,
      }),
    })
      .then(
        (res) => res.json()
        //res => res.text()
      )
      .then(
        (item) => {
          switch (item.pageTemplate) {
            case "ApplicationTwo":
              // eslint-disable-next-line prettier/prettier
              setPage(
                <ApplicationTwo
                  userAccess={item.userAccess}
                  imageReference={item.image1}
                  head1={item.contentName}
                  head2={"(" + item.pageNumber + " of " + item.pageTotal + ")"}
                  title={item.pageHeader}
                  item1={item.item1}
                  item2={item.item2}
                  item3={item.item3}
                  item1_title={item.title1}
                  item2_title={item.title2}
                  item3_title={item.title3}
                  description={item.pageInfo}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={
                    parseInt(item.pageNumber) >= item.pageTotal ? 0 : parseInt(item.pageNumber) + 1
                  }
                  lastpage={item.pageNumber - 1}
                />
              );
              break;
            case "ThreeItemsHorizontal":
              setPage(
                <FeaturesThree
                  userAccess={item.userAccess}
                  head1={item.contentName}
                  head2={"(" + item.pageNumber + " of " + item.pageTotal + ")"}
                  title={item.pageHeader}
                  description={item.pageInfo}
                  item1={item.item1}
                  item2={item.item2}
                  item3={item.item3}
                  title1={item.title1}
                  title2={item.title2}
                  title3={item.title3}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={
                    parseInt(item.pageNumber) >= item.pageTotal ? 0 : parseInt(item.pageNumber) + 1
                  }
                  lastpage={item.pageNumber - 1}
                />
              );
              break;
            case "FaqList":
              setPage(
                <FaqList
                  userAccess={item.userAccess}
                  head1={item.contentName}
                  head2={"(" + item.pageNumber + " of " + item.pageTotal + ")"}
                  title={item.pageHeader}
                  title1={item.title1}
                  info1={item.item1}
                  title2={item.title2}
                  info2={item.item2}
                  title3={item.title3}
                  info3={item.item3}
                  title4={item.title4}
                  info4={item.item4}
                  title5={item.title5}
                  info5={item.item5}
                  title6={item.title6}
                  info6={item.item6}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={
                    parseInt(item.pageNumber) >= item.pageTotal ? 0 : parseInt(item.pageNumber) + 1
                  }
                  lastpage={item.pageNumber - 1}
                />
              );
              break;
            case "ThreeItemsVertical":
              setPage(
                <ThreeItemsVertical
                  userAccess={item.userAccess}
                  head1={item.contentName}
                  head2={"(" + item.pageNumber + " of " + item.pageTotal + ")"}
                  title={item.pageHeader}
                  item1={item.item1}
                  item2={item.item2}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={
                    parseInt(item.pageNumber) >= item.pageTotal ? 0 : parseInt(item.pageNumber) + 1
                  }
                  lastpage={item.pageNumber - 1}
                />
              );
              break;
            case "TwoItems":
              setPage(
                <TwoItems
                  userAccess={item.userAccess}
                  head1={item.contentName}
                  head2={"(" + item.pageNumber + " of " + item.pageTotal + ")"}
                  title={item.pageHeader}
                  titleInfo={item.pageInfo}
                  title2={item.title1}
                  item1={item.item1}
                  item2={item.item2}
                  item3={item.item3}
                  item4={item.item4}
                  item5={item.item5}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={
                    parseInt(item.pageNumber) >= item.pageTotal ? 0 : parseInt(item.pageNumber) + 1
                  }
                  lastpage={item.pageNumber - 1}
                />
              );
              break;
            case "BlogText":
              setPage(
                <BlogText
                  userAccess={item.userAccess}
                  head1={item.contentName}
                  head2={"(" + item.pageNumber + " of " + item.pageTotal + ")"}
                  title={item.pageHeader}
                  item1={item.item1}
                  item2={item.item2}
                  item3={item.item3}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={
                    parseInt(item.pageNumber) >= item.pageTotal ? 0 : parseInt(item.pageNumber) + 1
                  }
                  lastpage={item.pageNumber - 1}
                />
              );
              break;
            case "TwoRowInfo":
              // eslint-disable-next-line prettier/prettier
              setPage(
                <TwoRowInfo
                  userAccess={item.userAccess}
                  head1={item.contentName}
                  head2={"(" + item.pageNumber + " of " + item.pageTotal + ")"}
                  title={item.pageHeader}
                  item1={item.item1}
                  item2={item.item2}
                  item1_title={item.title1}
                  item2_title={item.title2}
                  item_footer={item.pageFooter}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={
                    parseInt(item.pageNumber) >= item.pageTotal ? 0 : parseInt(item.pageNumber) + 1
                  }
                  lastpage={item.pageNumber - 1}
                />
              );
              break;
            case "Video Template":
              // eslint-disable-next-line prettier/prettier
              setPage(
                <VideoPageTemplate
                  userAccess={item.userAccess}
                  contentId={item.content_page_lesson_id}
                  head1={item.contentName}
                  head2={"(" + item.pageNumber + " of " + item.pageTotal + ")"}
                  title={item.pageHeader}
                  titleInfo={item.pageInfo}
                  video={item.video1}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={
                    parseInt(item.pageNumber) >= item.pageTotal ? 0 : parseInt(item.pageNumber) + 1
                  }
                  lastpage={item.pageNumber - 1}
                />
              );
              break;
            default:
              setPage(<FaqList />);
          }
        },
        (error) => {
          alert(error);
        }
      );
  }, []);

  return pageData;
};

export default LessonPage;
