/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Copyright 2023 A2K Solutions, Ltd
*/
import React, { useEffect, useState } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "../../../assets/style.css";
import { MdArrowForward } from "react-icons/md";
import { MdOutlineRefresh } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa6";

const QuizQuestion = () => {
  // eslint-disable-next-line no-undef
  const SERVER_URL = process.env.REACT_APP_TRAINING_URL;

  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [progress, setProgress] = useState("");
  const [islastQuestion, setIsLastQuestion] = useState(false);
  const [nextQuestion, setNextQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [completedQuiz, setCompletedQuiz] = useState(false);
  const [selected, setSelected] = useState("");
  const [quizId, setQuizId] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [marks, setMarks] = useState("");

  const lessonId = localStorage.getItem("contentValue");
  const userId = localStorage.getItem("userId");
  const trainingId = localStorage.getItem("trainingId");

  function getQuestions() {
    fetch(SERVER_URL + "/quizzes/getQuiz/", {
      method: "POST",
      body: JSON.stringify({
        lessonId,
        userId,
        trainingId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setQuizQuestions(result?.records);
          setCurrentQuestion(result?.records[0]);
          setTotalQuestions(result?.records.length);
          setNextQuestion(1);
          setQuizId(result?.id);
          setCurrentQuestionNumber(1);
          const progressPercentage = (1 / result?.records.length) * 100;
          setProgress(progressPercentage);
          setCompletedQuiz(false);
          setIsLastQuestion(false);
          setUserAnswers([]);
        },
        (error) => {
          alert(error);
        }
      );
  }
  function saveResult(marks) {
    fetch(SERVER_URL + "/quizzes/saveResult/", {
      method: "POST",
      body: JSON.stringify({
        userAnswers,
        quizId,
        marks,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          alert(error);
        }
      );
  }
  function next(index) {
    setCurrentQuestion(quizQuestions[index]);
    setNextQuestion(index + 1);
    setCurrentQuestionNumber(index + 1);
    const progressPercentage = ((index + 1) / totalQuestions) * 100;
    setProgress(progressPercentage);
    if (index === totalQuestions - 1) {
      setIsLastQuestion(true);
    }
  }
  // function prevoius(index) {
  //   if (currentQuestionNumber > 1) {
  //     setCurrentQuestion(quizQuestions[index - 2]);
  //     setNextQuestion(index);
  //     setCurrentQuestionNumber(index - 1);
  //     if (index === totalQuestions - 1) {
  //       setIsLastQuestion(true);
  //     }
  //   }
  // }
  function checkAnswer(questionId, selectedOption) {
    setSelected(selectedOption);

    setUserAnswers((prevAnswers) => {
      // Check if the questionId already exists in userAnswers.
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );

      if (existingAnswerIndex !== -1) {
        // If it exists, update the answer.
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex] = {
          ...updatedAnswers[existingAnswerIndex],
          userAnswer: selectedOption,
          isCorrect: selectedOption === quizQuestions[existingAnswerIndex].Correct_asnwer,
        };
        return updatedAnswers;
      } else {
        // If it doesn't exist, add a new answer.
        const currentQuestion = quizQuestions.find((q) => q.Question_id === questionId);
        const isCorrect = selectedOption === currentQuestion.Correct_asnwer;

        const answerObject = {
          questionId: questionId,
          userAnswer: selectedOption,
          isCorrect: isCorrect,
          marks: currentQuestion.Question_marks,
        };

        return [...prevAnswers, answerObject];
      }
    });
  }

  function completeQuiz() {
    const totalMarks = quizQuestions.reduce((total, question) => {
      return total + parseInt(question.Question_marks, 10);
    }, 0);
    const userMarks = userAnswers.reduce((total, answer) => {
      return total + (answer.isCorrect ? parseInt(answer.marks, 10) : 0);
    }, 0);
    setTotalMarks(totalMarks);
    setMarks(userMarks);
    saveResult((userMarks / totalMarks) * 100);
    setCompletedQuiz(true);
  }
  useEffect(() => {
    getQuestions();
    const progressPercentage = (currentQuestionNumber / totalQuestions) * 100;
    setProgress(progressPercentage);
  }, []);
  if (!completedQuiz) {
    return (
      <MKBox component="section" position="relative" height="800px" py={2}>
        <Container>
          <MKProgress color="primary" value={progress} />
          <MKBox component="nav" position="relative" width="100%">
            <Container py={5}>
              <Grid container flexDirection="row" alignItems="center">
                <MKTypography color="primary" fontWeight="regular" py={0.8125} mr={2}>
                  {"(" +
                    currentQuestionNumber +
                    " of " +
                    totalQuestions +
                    ")" +
                    " " +
                    progress +
                    "%"}
                </MKTypography>
                <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
                <MKBox
                  component="ul"
                  display={{ xs: "none", lg: "flex" }}
                  p={0}
                  my={0}
                  mx="auto"
                  sx={{ listStyle: "none" }}
                >
                  <MKTypography
                    color="primary"
                    fontWeight="regular"
                    py={0.8125}
                    mr={2}
                  ></MKTypography>
                </MKBox>
                <MKTypography py={1} mr={2}>
                  {currentQuestionNumber == 1 ? (
                    <> </>
                  ) : (
                    <> </>
                    // <MKButton
                    //   px={2}
                    //   color="default"
                    //   onClick={() => {
                    //     prevoius(currentQuestionNumber);
                    //   }}
                    // >
                    //   Go Back
                    // </MKButton>
                  )}
                  {islastQuestion ? (
                    <MKButton
                      color="primary"
                      onClick={() => {
                        completeQuiz();
                      }}
                    >
                      Complete &nbsp; <MdArrowForward />
                    </MKButton>
                  ) : (
                    <MKButton
                      px={2}
                      color="primary"
                      onClick={() => {
                        next(nextQuestion);
                      }}
                    >
                      Next &nbsp; <MdArrowForward />
                    </MKButton>
                  )}
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>
          <Grid container py={5} spacing={3} item xs={12} lg={8} mx="auto">
            <MKTypography variant="body2" style={{ width: "inherit" }} color="primary">
              <div className="question-block">{currentQuestion?.Question}</div>
              <br /> <br />
              <div
                className={
                  selected === currentQuestion.Option_1 ? "selected-block" : "option-block"
                }
                onClick={() => checkAnswer(currentQuestion.Question_id, currentQuestion.Option_1)}
              >
                {currentQuestion?.Option_1}
              </div>
              <div
                className={
                  selected === currentQuestion.Option_2 ? "selected-block" : "option-block"
                }
                onClick={() => checkAnswer(currentQuestion.Question_id, currentQuestion.Option_2)}
              >
                {currentQuestion?.Option_2}
              </div>
              <div
                className={
                  selected === currentQuestion.Option_3 ? "selected-block" : "option-block"
                }
                onClick={() => checkAnswer(currentQuestion.Question_id, currentQuestion.Option_3)}
              >
                {currentQuestion?.Option_3}
              </div>
              <div
                className={
                  selected === currentQuestion.Option_4 ? "selected-block" : "option-block"
                }
                onClick={() => checkAnswer(currentQuestion.Question_id, currentQuestion.Option_4)}
              >
                {currentQuestion?.Option_4}
              </div>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    );
  } else {
    return (
      <MKBox
        component="section"
        position="relative"
        minHeight="100vh"
        py={2}
        backgroundColor="#f5f5f5"
      >
        <Container>
          <MKProgress color="primary" value={progress} />
          <MKBox
            component="nav"
            position="relative"
            width="100%"
            backgroundColor="#fff"
            padding="20px"
          >
            <Container>
              <Grid container justifyContent="space-between" alignItems="center">
                <MKTypography variant="h6" color="textPrimary">
                  Quiz Progress: {progress}% <br />
                  Quiz Marks: {(marks / totalMarks) * 100}% <br />
                </MKTypography>
                <MKTypography variant="h6" color="textPrimary">
                  {marks >= (totalMarks / 100) * 70 ? (
                    <MKButton color="primary" href="/complete">
                      Complete Quiz &nbsp; <FaGraduationCap />
                    </MKButton>
                  ) : (
                    <MKButton variant="outlined" color="primary" onClick={() => getQuestions()}>
                      <MdOutlineRefresh /> &nbsp;Redo the Quiz
                    </MKButton>
                  )}
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>
          <Grid container py={5} spacing={3} item xs={12} lg={8} mx="auto">
            <MKTypography variant="body2" style={{ width: "100%" }}>
              <div className="summary-block">
                <h2>Quiz Summary</h2>
                <ul className="summary-list">
                  {quizQuestions.map((question, index) => (
                    <li key={question.Question_id} className="summary-item">
                      <strong>Question {index + 1}:</strong> {question.Question}
                      <br />
                      <div
                        className={
                          userAnswers.find((answer) => answer.questionId === question.Question_id)
                            ?.isCorrect
                            ? "Correct"
                            : "Incorrect"
                        }
                      >
                        <strong>Your Answer:</strong>{" "}
                        {userAnswers.find((answer) => answer.questionId === question.Question_id)
                          ?.userAnswer || "Not answered"}
                      </div>
                      <div className="Correct">
                        <strong>Correct Answer:</strong> {question.Correct_asnwer}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    );
  }
};

export default QuizQuestion;
