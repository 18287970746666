/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";
import PropTypes from "prop-types";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";

// Material Kit 2 PRO React examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

//import bgImage1 from "assets/images/examples/color1.jpg";

function ApplicationTwo({
  userAccess,
  imageReference,
  head1,
  head2,
  title,
  item1,
  item2,
  item3,
  item1_title,
  item2_title,
  item3_title,
  description,
  progress,
  nextpage,
  lastpage,
}) {
  ApplicationTwo.propTypes = {
    userAccess: PropTypes.string,
    imageReference: PropTypes.string,
    head1: PropTypes.string,
    head2: PropTypes.string,
    title: PropTypes.string,
    item1: PropTypes.string,
    item2: PropTypes.string,
    item3: PropTypes.string,
    item1_title: PropTypes.string,
    item2_title: PropTypes.string,
    item3_title: PropTypes.string,
    description: PropTypes.string,
    progress: PropTypes.number,
    nextpage: PropTypes.number,
    lastpage: PropTypes.number,
  };

  //const localURL = "http://localhost:3000";
  const liveURL = "https://demo.isongacyber.rw";
  const path = "/#/training";

  return (
    <MKBox component="section" position="relative" height="800px" py={2}>
      <Container>
        <MKProgress color="primary" value={progress} />
        <MKBox component="nav" position="relative" width="100%">
          <Container py={5}>
            <Grid container flexDirection="row" alignItems="center">
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
                {head1} {head2}
              </MKTypography>
              <MKBox
                component="ul"
                display={{ xs: "none", lg: "flex" }}
                p={0}
                my={0}
                mx="auto"
                sx={{ listStyle: "none" }}
              >
                <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
              </MKBox>
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
                {lastpage <= 0 ? (
                  <MKButton px={2} color="primary" href={"/start"}>
                    <MdArrowBack /> &nbsp; Back
                  </MKButton>
                ) : (
                  <MKButton px={2} color="primary" href={"/lesson/" + lastpage}>
                    <MdArrowBack /> &nbsp; Back
                  </MKButton>
                )}
              </MKTypography>
              {/* <MKButton color="default">Begin Training</MKButton> */}
            </Grid>
          </Container>
        </MKBox>
        <Grid
          container
          item
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          xs={10}
          lg={8}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h3" mt={3}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" color="text">
            {description}
          </MKTypography>
        </Grid>
        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <DefaultBackgroundCard
              image={`https://cyberback.a2k.solutions/media/uploads/${imageReference}`}
              // label="website visitors"
              title={title}
              description={description}
              // action={{
              //   type: "internal",
              // }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <ListItem title={item1_title}>{item1}</ListItem>
            <ListItem title={item2_title}>{item2}</ListItem>
            <ListItem title={item3_title}>{item3}</ListItem>
          </Grid>
        </Grid>
        <br></br>
        {nextpage == 0 ? (
          userAccess >= "2" ? (
            <MKButton color="primary" href={`${liveURL}${path}`} fullWidth>
              <MdArrowBack /> &nbsp; Return To Training
            </MKButton>
          ) : (
            <MKButton color="primary" href="/complete" fullWidth>
              <MdCheckCircle />
              &nbsp; Complete Training
            </MKButton>
          )
        ) : (
          <MKButton px={2} fullWidth color="primary" href={"/lesson/" + nextpage}>
            Next &nbsp; <MdArrowForward />
          </MKButton>
        )}
      </Container>
    </MKBox>
  );
}

ApplicationTwo.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "( 1 of 5 )",
  title: "Page Title",
  item1: "item1 info kitu kimoja kufanya",
  item2: "item2 info vitu wawili kufanya",
  item3: "item3 info vitu wawili kufanya",
  item1_title: "item 1 title",
  item2_title: "item 2 title",
  item3_title: "item 3 title",
  progress: 0,
  nextpage: "",
  description:
    "Page Description goes here. Kila siku ninahitaji kula chakula vitamu. Nataka matunde na mboga na nyama mingi sana.",
};

ApplicationTwo.propTypes = {
  userAccess: PropTypes.string,
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  item1: PropTypes.string,
  item2: PropTypes.string,
  item1_title: PropTypes.string,
  item2_title: PropTypes.string,
  description: PropTypes.string,
  item3: PropTypes.string,
  item3_title: PropTypes.string,
  progress: 0,
  nextpage: "",
  lastpage: "",
};
export default ApplicationTwo;
