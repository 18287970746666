/* eslint-disable no-undef */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useEffect, useState } from "react";
import axios from "axios";
import { createRoot } from "react-dom/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faPause } from "@fortawesome/free-solid-svg-icons";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { MdCheckCircle } from "react-icons/md";
import { MdArrowCircleLeft } from "react-icons/md";
import { MdArrowCircleRight } from "react-icons/md";

import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";

// Material Kit 2 PRO React examples
//import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function VideoPageTemplate({
  userAccess,
  contentId,
  head1,
  head2,
  title,
  video,
  nextpage,
  lastpage,
  progress,
}) {
  const SERVER_URL = process.env.REACT_APP_TRAINING_URL;

  var [contId] = useState(contentId);
  var [leftAtTime, setLeftAtTime] = useState(0);
  var [showButton, setShowButton] = useState(false);
  let vid,
    seekslider,
    curtimetext,
    durtimetext,
    leftAtTimeTotal,
    isCompleted = false,
    root,
    reachedTime;

  function initializePlayer() {
    vid = document.getElementById("my_video");
    seekslider = document.getElementById("seekslider");
    curtimetext = document.getElementById("curtimetext");
    durtimetext = document.getElementById("durtimetext");

    document.getElementById("rewindbtn").addEventListener("click", rewindVideo);
    seekslider.addEventListener("change", vidSeek, false);
    vid.addEventListener("timeupdate", seektimeupdate, false);
    updatePlayPauseButton();
    if (vid) {
      leftAtTimeTotal = leftAtTime;
      vid.currentTime = isFinite(leftAtTimeTotal) ? leftAtTimeTotal : 0;
    }
  }

  //------Get where left-----------
  useEffect(() => {
    axios
      .post(SERVER_URL + "/training_material_display/display/getPausedTime.php", {
        contId,
      })
      .then((res) => {
        setLeftAtTime(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [leftAtTime]);

  //----Start the Video------
  useEffect(() => {
    initializePlayer();
  }, []);

  //Where the video was left at
  function playPause() {
    if (vid.paused) {
      vid.play();
    } else {
      vid.pause();
      reachedTime = vid.currentTime;
      if (vid.currentTime === vid.duration) {
        isCompleted = true;
      }

      //Send to backend using Axios
      axios
        .post(SERVER_URL + "/training_material_display/display/onPause.php", {
          isPaused: reachedTime,
          isEnd: isCompleted,
          contId: contId,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    updatePlayPauseButton();
  }

  function vidSeek() {
    var seekto = vid.duration * (seekslider.value / 100);
    if (seekslider.value >= 1 && seekto <= vid.currentTime) {
      vid.currentTime = seekto;
    }
  }
  function rewindVideo() {
    if (vid && vid.currentTime !== undefined) {
      let rewindTime = 3;
      vid.currentTime = Math.max(0, vid.currentTime - rewindTime);
    } else {
      console.log("Video element is not initialized yet.");
    }
  }

  function seektimeupdate() {
    var nt = vid.currentTime * (100 / vid.duration);
    seekslider.value = nt;
    var curmins = Math.floor(vid.currentTime / 60);
    var cursecs = Math.floor(vid.currentTime - curmins * 60);
    var durmins = Math.floor(vid.duration / 60);
    var dursecs = Math.floor(vid.duration - durmins * 60);

    if (vid.currentTime === vid.duration) {
      setShowButton(true);
      //alert("Completed!");
    }

    if (cursecs < 10) {
      cursecs = "0" + cursecs;
    }

    if (dursecs < 10) {
      dursecs = "0" + dursecs;
    }

    if (curmins < 10) {
      curmins = "0" + curmins;
    }

    if (durmins < 10) {
      durmins = "0" + durmins;
    }

    curtimetext.innerHTML = curmins + ":" + cursecs;
    durtimetext.innerHTML = durmins + ":" + dursecs;
  }
  function updatePlayPauseButton() {
    const container = document.getElementById("playpausebtn");

    if (container) {
      if (!root) {
        root = createRoot(container);
      }
      root.render(
        vid.paused ? (
          <FontAwesomeIcon
            icon={faPlay}
            style={{ fontSize: "2em", cursor: "pointer" }}
            onClick={playPause}
          />
        ) : (
          <FontAwesomeIcon
            icon={faPause}
            style={{ fontSize: "2em", cursor: "pointer" }}
            onClick={playPause}
          />
        )
      );
    }
  }
  //const localURL = "http://localhost:3000";
  const liveURL = "https://demo.isongacyber.rw";
  const path = "/#/training";
  return (
    <MKBox component="section" py={2}>
      <MKProgress color="primary" value={progress} />
      <MKBox component="nav" position="relative" width="100%">
        <Container py={5}>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              {head1} {head2}
            </MKTypography>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
            </MKBox>
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              {lastpage <= 0 ? (
                <MKButton px={2} color="primary" href={"/start"}>
                  Go Back
                </MKButton>
              ) : (
                <MKButton px={2} color="primary" href={"/lesson/" + lastpage}>
                  Go Back
                </MKButton>
              )}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={8}
        >
          <MKTypography variant="h2" mb={1} py={4}>
            {title}{" "}
          </MKTypography>
          <br></br>
          <MKTypography color="text" py={2}>
            {/* {titleInfo} */}{" "}
          </MKTypography>
          <Grid item xs={12} md={6} lg={12}>
            <div id="video_player_box">
              <div id="video_display">
                <video id="my_video" width="100%" height="520px">
                  <source src={`https://cyberback.a2k.solutions/media/uploads/${video}`} />
                </video>
              </div>
              <div
                id="video_controls_bar"
                style={{
                  background: "#E17129",
                  padding: "10px",
                  color: "#CCC",
                  width: "75%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div id="playpausebtn" style={{ marginRight: "10px", minWidth: "50px" }}></div>
                <div id="rewindbtn" style={{ marginRight: "20px" }}>
                  <FontAwesomeIcon
                    icon={faBackward}
                    style={{ fontSize: "2em", cursor: "pointer" }}
                    onClick={rewindVideo}
                  />
                </div>
                <input
                  id="seekslider"
                  type="range"
                  min="0"
                  max="100"
                  value="0"
                  step="1"
                  style={{ width: "100%", margin: "0 10px", background: "#333", height: "4px" }}
                />
                <span id="curtimetext" style={{ fontSize: "1em", marginRight: "10px" }}>
                  00:00
                </span>
                <span id="durtimetext" style={{ fontSize: "1em", marginRight: "10px" }}>
                  00:00
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        {showButton &&
          (nextpage == 0 ? (
            userAccess >= "2" ? (
              <MKButton color="primary" href={`${liveURL}${path}`} fullWidth>
                <MdArrowCircleLeft /> &nbsp; Return To Training
              </MKButton>
            ) : (
              <MKButton color="primary" href="/complete" fullWidth>
                <MdCheckCircle />
                &nbsp; Complete Training
              </MKButton>
            )
          ) : (
            <MKButton px={2} fullWidth color="primary" href={"/lesson/" + nextpage}>
              <MdArrowCircleRight />
              &nbsp; Next
            </MKButton>
          ))}
      </Container>
    </MKBox>
  );
}

// Setting default props for the ExampleCard
VideoPageTemplate.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "( 3 of 5 )",
  title: "test",
  titleInfo: "Test",
  video: "Video ",
  contentId: "",
};

// Typechecking props for the ExampleCard
VideoPageTemplate.propTypes = {
  userAccess: PropTypes.string,
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  titleInfo: PropTypes.string,
  video: PropTypes.string,
  lastpage: "",
  contentId: "",
  nextpage: "",
  progress: 0,
};

export default VideoPageTemplate;
