/* eslint-disable no-unused-vars */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { updateBgImage } from "slice";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
// import Stack from "@mui/material/Stack";
// import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/isonga/Preloader.gif";
import bgAlt from "assets/images/isonga/trainingbackground.png";
import { useSelector, useDispatch } from "react-redux";

function LoadingPage() {
  // eslint-disable-next-line no-undef
  const SERVER_URL = process.env.REACT_APP_TRAINING_URL;
  const dispatch = useDispatch();

  let myParam = "";
  let userId = "";
  let trainingId = "";

  if (!localStorage.getItem("contentValue") || localStorage.getItem("contentValue") === "null") {
    const urlParams = new URLSearchParams(window.location.search);
    myParam = urlParams.get("content");
    userId = urlParams.get("userId");
    trainingId = urlParams.get("trainingId");

    if (myParam && userId && trainingId) {
      localStorage.setItem("contentValue", myParam);
      localStorage.setItem("userId", userId);
      localStorage.setItem("trainingId", trainingId);

      // const baseUrl = window.location.href.split("?")[0];
      // window.history.replaceState({}, document.title, baseUrl);
    }
  } else {
    myParam = localStorage.getItem("contentValue");
    userId = localStorage.getItem("userId");
    trainingId = localStorage.getItem("trainingId");
    // const baseUrl = window.location.href.split("?")[0];
    // window.history.replaceState({}, document.title, baseUrl);
  }

  useEffect(() => {
    fetch(SERVER_URL + "/media/getMediaReference/", {
      method: "POST",
      body: JSON.stringify({
        contentId: myParam,
        contentPage: 0,
        userId,
        trainingId,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          const imageUrl = `${SERVER_URL}/media/uploads/${result.imageReference}`;
          dispatch(updateBgImage(imageUrl));
          //dispatch(updateBgImage(result.imageReference));
        },
        (error) => {
          alert(error);
        }
      );
  }, []);

  //--- Get the Image form url from the store ---
  const storeBgImage = useSelector((state) => state.image.imageRef);
  //const bgImageReference = `${SERVER_URL}/media/uploads/${storeBgImage}`;
  console.log(storeBgImage, "STORE BG IMAGE");
  //console.log(bgImageReference, "bgImageReference");

  return (
    <MKBox
      component="header"
      position="relative"
      height="800px"
      style={{ backgroundImage: `url(${bgAlt})` }}
    >
      {/* <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography color="white" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li"></MKBox>
              <MKBox component="li"></MKBox>
            </MKBox>
            <MKTypography color="white" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
          </Grid>
        </Container>
      </MKBox> */}
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(${bgImage})`,
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {/* <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mb={3}
            ></MKTypography>
            <MKTypography
              variant="h5"
              color="white"
              mt={1}
              mb={{ xs: 3, sm: 8 }}
              px={3}
            ></MKTypography>
          </Grid>
        </Container> */}
      </MKBox>
    </MKBox>
  );
}
export default LoadingPage;
