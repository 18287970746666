import { createSlice } from "@reduxjs/toolkit";

//--- Initial state ---

const initialState = {
  imageRef: "",
};

//--- Creating the reducer ---

export const imageRefSlice = createSlice({
  name: "bgRefImage",
  initialState,
  reducers: {
    updateBgImage(state, action) {
      state.imageRef = action.payload;
      console.log(action.payload, "ACTION PAYLOAD");
    },
  },
});

//=== Export our action ===
export const { updateBgImage } = imageRefSlice.actions;
export default imageRefSlice.reducer;
